export const addFavouriteOffers = `mutation addFavourite($offerId:Long!){
    addFavourite(offerId:$offerId) {
      createdOn
      favouriteOfferId {
          offerId
          memberId
      }
    }
  }`;
export const removeFavouriteOffers = `mutation delete($offerId:Long!){
    removeFavourite(offerId:$offerId) 
  }`;

export const updateProfile = `mutation updateProfile($profile: ProfileInput!){
    updateProfile(profile:$profile){
      dob
      firstUse
      permissions{
        call
        sms
        email
        location
        profiling
        traffic
      }
      selfReferralPending
      interests{
        id,
        name
      }
    }
  }`;

export const claimOffer = `mutation claimOffer($claimRequest: OfferClaimRequestInput!) {
  claimOffer(claimRequest: $claimRequest) {
    id
    attributes
    byAgent
    claimDateTime
    expiryDateTime
    offerId
    offerType
    redemptionCode
    prizeName
    restaurantId
    prizeType
  }
}`;

export const shareOffer = `mutation shareOffer($claimId: Long!, $platform: Platform) {
    shareOffer(shareOffer: { claimId: $claimId, platform: $platform }) {
      id
      claimId
      offerId
      platform
      claimDateTime
      expiryDateTime
      redemptionCode
      sharedOn
      source
      sharingUrl
    }
  }`;

export const saveGamificationDetails = `mutation saveGamificationDetails($claimId: Long!, $firstName: String!, $lastName: String!, $email: String!, $memberRegId: String!, $msisdn: String!, $offerPrizeName: String!, $offerId: Long!){
    saveGamificationDetails(
      gamificationData:{
        claimId: $claimId
        firstName: $firstName
        lastName: $lastName
        email: $email
        msisdn: $msisdn
        offerPrizeName: $offerPrizeName
        offerId: $offerId
      },
      memberRegId: $memberRegId
      ){
        id
        claimId
        firstName
        lastName
        email
    }
  }`;

export const saveOfferRatingDetails = `mutation saveOfferRatingDetails($id: Long, $customerId: String!, $offerRating: Int!, $offerId: Long!, $memberRegId: String!, $feedback: String) {
    saveOfferRatingDetails(memberRegId: $memberRegId,
      offerRatingDetails: {
        id: $id
        customerId: $customerId
        offerId: $offerId  
        offerRating: $offerRating
        feedback: $feedback
      }){
      id
      customerId
      offerId
      offerRating
      feedback
    }
  }`;
