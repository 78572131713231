import { BaseModel } from "ReduxStore/index";
import { OfferType } from "../Interfaces/OfferType";

export interface ClaimedOffer {
  claimedId: number;
  offerId: number;
  offerType: OfferType;
  redemptionCode: string;
  expiryDateTime: Date | undefined;
  claimDateTime: Date;
  byAgent: string;
  source?: string;
  attributes: any;
  prizeName: string | null;
  prizeType: string | null;
}
export interface IClaimedOffers {
  [key: number]: ClaimedOffer;
}
export default class ClaimedOffers extends BaseModel<IClaimedOffers> {
  static resource = "ClaimedOffers";
}
