export enum Actions {
  PRIZE_DRAW_ENTERED = "prize-draw-entered",
  SHARED = "shared",
  INSTANT_WIN_LOOSE = "instant-win-loose",
  USE = "use",
  LOCKED = "locked",
  PRIZE_DRAW = "prize-draw",
  ENTER_PRIZE_DRAW = "enter-prize-draw",
  CLAIM = "claim",
  NOP = "nop",
  UNAVAILABLE = "unavailable",
  CLAIM_AGAIN = "claim-again",
  VIEW_CARD = "view card",
  LUCKY_DRAW_WIN = "Show code",
  VISIT_TO_WIN = "visit-to-win",
  VISIT_TO_WIN_ENTERED = "visit-to-win-entered",
  ENTER_VISIT_TO_WIN = "enter-visit-to-win",
  GAMIFICATION_CLAIM_PRIZE = "gamification-claim-prize",
  EAT_LOCAL_RENEW_MEMBERSHIP = "renew-membership",
  SPIN_TO_WIN = "spin_to_win",
  GRAND_PRIZE = "grand-prize"
}
