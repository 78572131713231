import {
  newOnboardingRoutePaths,
  saveCMSImagesDataInRedux
} from "./../Utils/GeneralUtils";
import { GQLConstants, graphQlCall } from "Utils/GraphQLUtils";
import { payloadGuard } from "Models/Schema/FetchAllSchema";
import {
  isEmptyObject,
  isoToLocalDateConversion,
  getValueFromHashMapArray,
  getQueryVariable,
  toNonHypenCharachter,
  saveDateTimeDiffrence,
  getClientName
} from "Utils/GeneralUtils";
import { ClientModel } from "Models/ReduxModels/ClientDetails";
import Interests, {
  IInterests,
  ProfileInterest
} from "Models/ReduxModels/Interests";
import Carousel from "Models/ReduxModels/Carousel";
import ClaimedOffers, {
  ClaimedOffer,
  IClaimedOffers
} from "Models/ReduxModels/ClaimedOffersModel";
import DisplayCategories, {
  IDisplayCategory,
  DisplayCategory,
  DisplayCategoryIds
} from "Models/ReduxModels/DisplayCategories";
import FavouriteOffers from "Models/ReduxModels/FavouriteOffersModel";
import UserDetails, { AppVersion } from "Models/ReduxModels/UserDetails";
import UserProfile, {
  ProfilePermission
} from "Models/ReduxModels/UserProfileModel";
import Merchants, { IMerchant, Merchant } from "Models/ReduxModels/Merchants";
import Offers, {
  Offer,
  IOffers,
  RedemptionCodeType,
  RedemptionDetail,
  RedemptionMethod,
  ClaimLimitDetails,
  KeyValuePair,
  Image,
  ImageClassifier,
  RedemptionExpiryModel,
  ComingSoonDetailsModel,
  OfferPrizeDetail
} from "Models/ReduxModels/OffersModel";
import Faq from "Models/ReduxModels/FaqModel";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import { OfferType } from "Models/Interfaces/OfferType";
import AppConfig, {
  AppSectionConfig,
  IAppSectionConfig
} from "Models/ReduxModels/AppConfig";
import CollectionCardDetailsModel, {
  CollectionCardItem,
  ICollectionCardDetails
} from "Models/ReduxModels/CollectionCardDetailsModel";
import {
  getLatestClaimDetailsByClaimId,
  isObjectEmpty,
  retireveBannerDetails
} from "Utils/offerUtils";
import OfferIdsSortedByWeight from "Models/ReduxModels/OfferIdsSortedByWeight";
import { v4 as uuidv4 } from "uuid";
import SortedClaimedIds from "Models/ReduxModels/SortedClaimedIds";
import SortedFavouriteOfferIds from "Models/ReduxModels/SortedFavouriteOfferIds";
import { EVENT_TYPE, logEvents } from "./EventService";
import {
  ConstantMapping,
  GQLConstantMapping,
  MockDataMapping
} from "Configurations/ConstantsMapping";
import Filters, { Filter, IFilter } from "Models/ReduxModels/FilterModel";
import { HashRoutesMapper } from "Configurations/RoutesMapper";
import RouteParser from "route-parser";
import { Settings } from "Models/ReduxModels/Settings";
import HttpClient from "Utils/Http";
import Announcement, { IAnnouncement } from "Models/ReduxModels/Announcement";

interface OfferIdClaimIdsMap {
  [key: number]: number[];
}

export const updateInitialState = () => {
  return new Promise(async (resolve, reject) => {
    let sessionId = localStorage.getItem("session-id");
    let token = localStorage.getItem("token");
    let queryToken = getQueryVariable("token");

    if (!token && !queryToken) {
      reject(new Error("Unauthorized request "));
    } else if (queryToken) {
      sessionId = uuidv4();
      token = queryToken;
      localStorage.setItem("token", queryToken);
      localStorage.setItem("session-id", sessionId ? sessionId : "");
    } else if (!sessionId) {
      sessionId = uuidv4();
      localStorage.setItem("session-id", sessionId ? sessionId : "");
    }

    let clientName = getClientName();
    if (clientName) {
      localStorage.removeItem(
        ConstantMapping[clientName].APP_UPDATE_DIALOG_LOCAL_STORAGE_KEY
      );
    }

    let data: any = await getSettings();
    saveSettings(data);

    try {
      updateClientDetailsInStore(sessionId, token);
      /////////////////////////////////////fetching bff response/////////////////////////////////////////////////
      //setting custom header as we don't have token in redux as this function is run at very first time app loads
      let headers = {
        Authorization: `Bearer ${token}`,
        tenantId: data.clientDetails[clientName].tenantId,
        sessionId: sessionId
      };
      fetchAllGraphQlCall(
        {
          tenantId: data.clientDetails[clientName].tenantId.toString()
        },
        headers
      )
        .then(response => {
          response ? resolve(response) : reject(new Error("API error"));
        })
        .catch(error => {
          console.error(error, "APP_ERROR");
          if (error && error.length) {
            reject(error);
          } else {
            reject(new Error(error.toString()));
          }
        });
    } catch (error) {
      reject(new Error(error?.toString()));
    }
  });
};

const getSettings = async () => {
  const settingURL = process.env.PUBLIC_URL + "/settings.json";
  const httpClient = new HttpClient();

  let settingsResponse = await httpClient.get(settingURL);
  if (settingsResponse && settingsResponse.data) return settingsResponse.data;
  else {
    console.error("APP_ERROR", "Unable to get settings");
    let settingsResponse = await httpClient.get(settingURL);
    if (settingsResponse && settingsResponse.data) return settingsResponse.data;
  }
};

const saveSettings = settingResponse => {
  let clientName = getClientName();
  if (settingResponse && clientName) {
    new Settings({
      id: "",
      clientDetails: settingResponse.clientDetails[clientName]
    }).$save();
  }
};

export const getParsedRoute = (route: string) => {
  if (!route || !route.length) {
    return false;
  }
  let clientName = getClientName();
  let matchedValue: string | boolean = false;
  if (clientName) {
    const hashRoutesMapping = HashRoutesMapper[clientName];
    const oldRoutes = Object.keys(hashRoutesMapping);
    oldRoutes.forEach(oldRoute => {
      let parser = new RouteParser(oldRoute);
      let value = parser.match(route);
      if (value) {
        matchedValue = hashRoutesMapping[oldRoute];
        if (value.id) {
          matchedValue += "/" + value.id.toString();
        }
      }
    });
  }
  return matchedValue;
};

export const handleHashedRoute = () => {
  let newPath = "/home";
  const location = window.location;
  const pathName = location.pathname;
  newPath = pathName;
  if (location.hash) {
    let hash = location.hash.replace("#/", "");
    let parsedValue = getParsedRoute(hash);
    window.history.replaceState(
      { prevUrl: window.location.origin },
      "",
      "/home"
    );
    if (parsedValue) {
      newPath += parsedValue;
    }
  }
  if (newPath === "/" || newPath.indexOf("error") > -1) {
    newPath = "/home";
  }
  window.history.pushState("", newPath, newPath);
};

//separate function as this function can be used at multiple places
export const fetchAllGraphQlCall = (variable?: {}, headers?: {}) => {
  const clientName = getClientName();

  return new Promise((resolve, reject) => {
    let data: any = {};
    //mock data
    // let mock = true;
    let mock = false;
    if (mock) {
      let response: any;
      response = MockDataMapping[clientName]["fetch-all"];

      if (response && response.data && response.data.fetchAll) {
        let checkResponse = payloadGuard(response.data.fetchAll); //payloadGuard(response.data.fetchAll);
        // let checkResponse = response.data.fetchAll;
        saveDateTimeDiffrence(checkResponse);
        logEvents({ eventType: EVENT_TYPE.FETCH_USER_INFO });
        if (!isEmptyObject(checkResponse)) {
          // Need to normalize data here
          let offerIdStringValue;
          if (response.data.fetchAll?.profile?.firstUse) {
            const link = window.location.href;
            if (link && link.indexOf("#/offer") !== -1) {
              const offerIdString = link.split("/").pop();
              let offerId = offerIdString;
              const getIsActiveFlag = response?.data?.fetchAll?.offers?.find(
                obj => obj.id == offerId
              );

              const isQRActiveValue = getIsActiveFlag?.isQRActive;

              if (isQRActiveValue) {
                offerIdStringValue = `/offer-details/${offerId}`;
              }
            }
          }
          saveNormalizedDataInRedux(checkResponse, offerIdStringValue);
          saveCMSImagesDataInRedux(checkResponse.appConfig);
          // updateFetchAllDataInStore(checkResponse);

          if (response.data.fetchAll?.profile?.firstUse) {
            //console.log("firstUse",response.data.fetchAll.profile.firstUse);
            //const deepLink = "http://localhost:13131/offer-details/885930";

            window.history.replaceState("", "LandingScreen", "/landing");
          } else {
            handleHashedRoute();
          }
          resolve(response);
        } else {
          reject(new Error("API error"));
        }
      } else if (response && response.errors) {
        reject(response.errors);
      } else {
        reject(new Error("API error"));
      }
    } else
      graphQlCall(
        GQLConstants.QUERY,
        GQLConstantMapping[clientName].queries.FETCH_ALL,
        variable,
        headers
      )
        ?.then((response: any) => {
          // response = mockData;
          if (response && response.data && response.data.fetchAll) {
            data = response.data.fetchAll;
            let checkResponse = payloadGuard(response.data.fetchAll);
            // let checkResponse = response.data.fetchAll;

            //save clientSeverTime diffrence in localstorage
            saveDateTimeDiffrence(checkResponse);
            logEvents({ eventType: EVENT_TYPE.FETCH_USER_INFO });
            if (!isEmptyObject(checkResponse)) {
              let offerIdStringValue;
              if (response.data.fetchAll?.profile?.firstUse) {
                const link = window.location.href;
                if (link && link.indexOf("#/offer") !== -1) {
                  const offerIdString = link.split("/").pop();
                  let offerId = offerIdString;
                  const getIsActiveFlag =
                    response?.data?.fetchAll?.offers?.find(
                      obj => obj.id == offerId
                    );
                  const isQRActiveValue = getIsActiveFlag?.isQRActive;

                  if (isQRActiveValue) {
                    offerIdStringValue = `/offer-details/${offerId}`;
                  }
                }
              }
              // Need to normalize data here
              saveNormalizedDataInRedux(checkResponse, offerIdStringValue);
              //Matomo
              try {
                // @ts-ignore
                const _paq = window._paq || [];
                _paq.push([
                  "setUserId",
                  response.data.fetchAll.externalMemberRegistrationId
                ]);
              } catch (er) {
                console.error(er, "APP_ERROR");
              }

              if (data.profile.firstUse) {
                window.history.replaceState("", "LandingScreen", "/landing");
              } else {
                handleHashedRoute();
              }
              // updateFetchAllDataInStore(data);
              resolve(response);
            } else {
              reject(new Error("API error"));
            }
          } else if (response && response.errors) {
            reject(response.errors);
          } else {
            reject(new Error("API error"));
          }
        })
        .catch(error => {
          console.error(error, "APP_ERROR");
          reject(
            error && error.graphQLErrors && error.graphQLErrors.length
              ? error.graphQLErrors
              : new Error(error.toString())
          );
        });
  });
};

const updateClientDetailsInStore = (
  sessionId: string | null,
  token: string | null
) => {
  new ClientModel({
    token: token,
    id: "",
    sessionId: sessionId
  }).$save();
};

const saveNormalizedDataInRedux = (
  fetchAllApiResponse: any,
  offerIdStringValue?
) => {
  // const apiDateFormat = "yyyy-MM-dd HH:mm:ss ";
  var appConfig = {};
  //Save App Config & Interests In redux
  if (fetchAllApiResponse.appConfig) {
    if (
      fetchAllApiResponse.appConfig.appSectionList &&
      fetchAllApiResponse.appConfig.appSectionList.length
    ) {
      const { appSectionList } = fetchAllApiResponse.appConfig;
      appSectionList.forEach((appSection: any) => {
        let key = appSection.name;
        let config: IAppSectionConfig = {
          key: key,
          value: []
        };
        if (
          appSection.configurationDataList &&
          appSection.configurationDataList.length
        ) {
          appSection.configurationDataList.forEach((configData: any) => {
            let appSectionRedux: AppSectionConfig = {
              name: configData.name,
              type: configData.type,
              value: configData.value
            };
            config.value.push(appSectionRedux);
          });
        }
        appConfig[config.key] = config;
        new AppConfig({
          id: config.key,
          ...config
        }).$save();
      });
    }
    if (
      fetchAllApiResponse.appConfig.interests &&
      fetchAllApiResponse.appConfig.interests.length
    ) {
      const { interests } = fetchAllApiResponse.appConfig;
      const appInterests: IInterests = {};
      interests.forEach((interest: ProfileInterest) => {
        appInterests[interest.id] = interest;
      });
      new Interests({
        id: "",
        ...appInterests
      }).$save();
    }
  }

  //Save Carousel
  const carousel: any[] = [];

  const collectionCardDetails: ICollectionCardDetails = {};
  if (fetchAllApiResponse.carousel && fetchAllApiResponse.carousel.length) {
    fetchAllApiResponse.carousel.forEach((carouselItem: any) => {
      let card = carouselItem?.collectionCard;
      let carouselDetails = {
        label: carouselItem.label,
        offerId: carouselItem.offerId,
        collectionCardId: card?.id || null
      };
      carousel.push(carouselDetails);
      //Collection Card
      if (card?.id) {
        let images = card?.images;
        let offerIds = card?.offerIds;
        let cardImages: any = {};
        if (images && images.length) {
          images.forEach((image: any) => {
            let cardImage: any = {
              url: image.imageUrl,
              altText: image.altText || "",
              classifier: image.classifier
            };
            cardImages[image.classifier] = cardImage;
          });
        }
        let collectionCard: CollectionCardItem = {
          id: card?.id,
          images: cardImages,
          offerIds: offerIds,
          showOverlayText: card?.showOverlayText,
          subTitle: card?.subTitle,
          textColor: card?.textColor,
          title: card?.title
        };
        collectionCardDetails[card?.id] = collectionCard;
      }
    });

    new Carousel({
      id: "",
      carousel: carousel
    }).$save();

    new CollectionCardDetailsModel({
      id: "",
      ...collectionCardDetails
    }).$save();
  } else {
    new Carousel({
      id: "",
      carousel: []
    }).$save();
  }

  //Claimed Offers
  const claimedOffers: IClaimedOffers = {};
  const offerIdClaimIdsMap: OfferIdClaimIdsMap = {};
  const sortedclaimedIds: number[] = [];
  if (
    fetchAllApiResponse.claimedOffers.claims &&
    fetchAllApiResponse.claimedOffers.claims.length
  ) {
    fetchAllApiResponse.claimedOffers.claims.forEach((offerClaimed: any) => {
      let claimedOffer: ClaimedOffer = {
        claimedId: offerClaimed.id,
        offerId: offerClaimed.offerId,
        offerType: offerClaimed.offerType,
        redemptionCode: offerClaimed.redemptionCode,
        expiryDateTime: offerClaimed.expiryDateTime
          ? isoToLocalDateConversion(offerClaimed.expiryDateTime)
          : undefined,
        claimDateTime: isoToLocalDateConversion(offerClaimed.claimDateTime),
        byAgent: offerClaimed.byAgent,
        attributes: offerClaimed.attributes,
        source: offerClaimed.source,
        prizeName: offerClaimed.prizeName,
        prizeType: offerClaimed?.prizeType
      };
      sortedclaimedIds.push(offerClaimed.id);
      if (!offerIdClaimIdsMap[offerClaimed.offerId]) {
        offerIdClaimIdsMap[offerClaimed.offerId] = [];
      }
      offerIdClaimIdsMap[offerClaimed.offerId].push(offerClaimed.id);
      claimedOffers[offerClaimed.id] = claimedOffer;
    });

    new ClaimedOffers({
      id: "",
      ...claimedOffers
    }).$save();
    new SortedClaimedIds({
      id: "",
      claimIds: sortedclaimedIds
    }).$save();
  } else {
    new ClaimedOffers({
      id: "",
      ...claimedOffers
    }).$save();
    new SortedClaimedIds({
      id: "",
      claimIds: sortedclaimedIds
    }).$save();
  }

  //Display categories
  if (
    fetchAllApiResponse.displayCategories &&
    fetchAllApiResponse.displayCategories.length
  ) {
    const appDisplayCategories: IDisplayCategory = {};
    let appDisplayCategoryIds: number[] = [];
    fetchAllApiResponse.displayCategories.forEach(
      (displayCategory: DisplayCategory) => {
        if (!appDisplayCategories[displayCategory.id]) {
          appDisplayCategories[displayCategory.id] = displayCategory;
          appDisplayCategoryIds.push(displayCategory.id);
        }
      }
    );

    // filters data
    let filterCategories: IFilter = {};
    appDisplayCategoryIds?.forEach((id: number, index: number) => {
      let selectedFilter: Filter = {
        name: id + "",
        label: appDisplayCategories[id]?.name,
        isSelected: false,
        position: index + 1,
        selectOrder: index + 1
      };
      filterCategories[id] = selectedFilter;
    });

    new Filters({
      id: "",
      ...filterCategories
    }).$save();

    new DisplayCategoryIds({
      id: "",
      ids: appDisplayCategoryIds
    }).$save();

    new DisplayCategories({
      id: "",
      ...appDisplayCategories
    }).$save();
  }

  //Favorite offers
  let favouriteOffers: any = {};
  let sortedFavouriteOfferIds: number[] = [];
  if (
    fetchAllApiResponse.favourites &&
    fetchAllApiResponse.favourites.favourites &&
    fetchAllApiResponse.favourites.favourites.length
  ) {
    fetchAllApiResponse.favourites.favourites.forEach((savedOffer: any) => {
      //TODO need to find a way to translate different objects structures per client
      // to avoid below condition
      let saverOfferId = savedOffer.favouriteOfferId
        ? savedOffer.favouriteOfferId.offerId
        : savedOffer.offerId;
      let favouriteOffer: any = {
        offerId: saverOfferId,
        createdOn: savedOffer.createdOn
      };
      sortedFavouriteOfferIds.push(saverOfferId);
      favouriteOffers[saverOfferId] = favouriteOffer;
    });
    new FavouriteOffers({
      id: "",
      ...favouriteOffers
    }).$save();
    new SortedFavouriteOfferIds({
      id: "",
      offerIds: sortedFavouriteOfferIds
    }).$save();
  } else {
    new FavouriteOffers({
      id: "",
      ...favouriteOffers
    }).$save();
    new SortedFavouriteOfferIds({
      id: "",
      offerIds: []
    }).$save();
  }

  let appVersionParts = fetchAllApiResponse.appVersion
    .split(".")
    .map(v => parseInt(v));
  let appVersion: AppVersion = {
    major: appVersionParts[0],
    minor: appVersionParts[1]
  };

  //UserDetails
  new UserDetails({
    id: "",
    externalId: fetchAllApiResponse.externalMemberId,
    firstName: fetchAllApiResponse.firstName,
    lastName: fetchAllApiResponse.lastName,
    userId: fetchAllApiResponse.id,
    totalClaimedOffers: fetchAllApiResponse.totalClaimedOffers,
    appVersion: appVersion,
    appAccessLevel: fetchAllApiResponse?.attributes?.appAccessLevel,
    email: fetchAllApiResponse?.attributes?.email || "",
    halfVisitToWinPopup: fetchAllApiResponse?.attributes?.halfVisitToWinPopup,
    fullVisitToWinPopup: fetchAllApiResponse?.attributes?.fullVisitToWinPopup,
    offerEntries: fetchAllApiResponse?.attributes?.offerEntries,
    isConvergedUser: fetchAllApiResponse?.attributes?.isConvergedUser,
    currentUserType: fetchAllApiResponse?.attributes?.customerType,
    chatToken: fetchAllApiResponse?.attributes?.chatToken,
    externalRegistrationId: fetchAllApiResponse.externalMemberRegistrationId,
    referralsData: fetchAllApiResponse?.referrals,
    eatLocalToken: fetchAllApiResponse?.attributes["Eat Local"],
    coffeeClubToken: fetchAllApiResponse?.attributes["Coffee Club"]
  }).$save();

  //UserProfile
  if (fetchAllApiResponse.profile) {
    let appProfilePermissions: ProfilePermission = {
      call: false,
      email: false,
      location: false,
      profiling: false,
      sms: false,
      traffic: false
    };
    const { permissions, dob, firstUse, interests } =
      fetchAllApiResponse.profile;
    const { selfReferralPending } = fetchAllApiResponse.referrals;
    if (permissions) {
      appProfilePermissions = {
        call: permissions.call,
        email: permissions.email,
        location: permissions.location,
        profiling: permissions.profiling,
        sms: permissions.sms,
        traffic: permissions.traffic
      };
    }

    let selectedInterests: number[] = [];
    if (interests && interests.length) {
      interests.forEach((interest: ProfileInterest) => {
        selectedInterests.push(interest.id);
      });
    }
    const currentOnboardingPage = 0;
    let totalOnboardingPages = new Array();

    totalOnboardingPages.push("landing");
    const permissionKeys = Object.keys(permissions);
    for (let item of newOnboardingRoutePaths) {
      if (permissionKeys.includes(item) && permissions[item] === false) {
        totalOnboardingPages.push(item);
      }
    }
    totalOnboardingPages.push("summary");

    new UserProfile({
      id: "",
      permissions: appProfilePermissions,
      dob: dob,
      isFirstUse: firstUse,
      interests: selectedInterests,
      currentOnboardingPage,
      totalOnboardingPages,
      selfReferralPending: selfReferralPending
    }).$save();
    new UserProfile({
      id: "local",
      permissions: appProfilePermissions,
      dob: dob,
      isFirstUse: firstUse,
      interests: selectedInterests,
      currentOnboardingPage,
      totalOnboardingPages,
      selfReferralPending: selfReferralPending
    }).$save();
  }

  //Offers Data
  const apiOffers: IOffers = {};
  const apiMerchant: IMerchant = {};
  const offerIdsSortedByWeight: number[] = [];
  if (fetchAllApiResponse.offers && fetchAllApiResponse.offers.length) {
    fetchAllApiResponse.offers.forEach((apiOffer: any, index: number) => {
      const {
        additionalDetails,
        claimConditionsFailed,
        claimLimitsReached,
        ctaDetails,
        merchant,
        images,
        redemptionDetails,
        startDateTime,
        id,
        showPreClaimPopup,
        comingSoonDetails,
        offerPrizeDetails,
        isStoreGiftingOffer,
        isConvergedOffer,
        captureEmail,
        offerRating,
        isReferralReward
      } = apiOffer;
      offerIdsSortedByWeight.push(id);
      //update Merchants
      if (!apiMerchant[merchant.id]) {
        const storeMerchant: Merchant = {
          merchantId: merchant.id,
          imageUrl: merchant.imageUrl,
          name: merchant.name
        };

        apiMerchant[merchant.id] = storeMerchant;
      }

      //Redemption Details
      let offerRedemptions: RedemptionDetail[] = [];
      if (redemptionDetails && redemptionDetails.length) {
        apiOffer.redemptionDetails.forEach((offerRedemptionDetails: any) => {
          let apiRedemptionDetails: RedemptionDetail = {
            guidelines: offerRedemptionDetails?.guidelines,
            redemptionMethod: (<any>RedemptionMethod)[
              offerRedemptionDetails.method
            ],
            redemptionType: (<any>RedemptionCodeType)[
              offerRedemptionDetails.type
            ],
            url: offerRedemptionDetails.url,
            prizeName: offerRedemptionDetails.prizeName,
            captureEmail: offerRedemptionDetails.captureEmail
          };
          offerRedemptions.push(apiRedemptionDetails);
        });
      }

      //ClaimsLimit
      let claimsLimit: ClaimLimitDetails = {
        notEnoughCodes: claimLimitsReached.notEnoughCodes,
        total: claimLimitsReached.total,
        totalPerPeriod: claimLimitsReached.totalPerPeriod,
        user: claimLimitsReached.user,
        userPerPeriod: claimLimitsReached.userPerPeriod
      };

      let claimedIds = offerIdClaimIdsMap[apiOffer.id];
      let claimedDetails = getLatestClaimDetailsByClaimId(
        claimedIds,
        claimedOffers
      );
      let instantWinResult = null;
      let gamificationEmailPopup = null;
      if (claimedDetails) {
        instantWinResult = getValueFromHashMapArray(
          claimedDetails.attributes,
          "InstantWin"
        );
        gamificationEmailPopup = getValueFromHashMapArray(
          claimedDetails.attributes,
          "gamificationStatus"
        );
      }

      let offerAdditionalDetails: KeyValuePair[] = [];
      if (additionalDetails && additionalDetails.length) {
        additionalDetails.forEach((offerAdditionalDetail: KeyValuePair) => {
          offerAdditionalDetails.push(offerAdditionalDetail);
        });
      }

      let callToActionDetails: KeyValuePair[] = [];
      if (ctaDetails && ctaDetails.length) {
        ctaDetails.forEach((ctaDetail: KeyValuePair) => {
          callToActionDetails.push(ctaDetail);
        });
      }

      let apiImages: Image = {};
      if (images && images.length) {
        images.forEach((image: any) => {
          let apiImage: any = {
            url: image.imageUrl,
            altText: image.altText || "",
            classifier: image.classifier
          };
          apiImages[image.classifier] = apiImage;
        });
      }

      if (!apiImages[ImageClassifier.OFFER_IMAGE_SMALL]) {
        if (!apiImages[ImageClassifier.OFFER_IMAGE_MEDIUM]) {
          apiImages[ImageClassifier.OFFER_IMAGE_SMALL] =
            apiImages[ImageClassifier.OFFER_IMAGE_LARGE];
        } else {
          apiImages[ImageClassifier.OFFER_IMAGE_SMALL] =
            apiImages[ImageClassifier.OFFER_IMAGE_MEDIUM];
        }
      }
      if (!apiImages[ImageClassifier.OFFER_IMAGE_MEDIUM]) {
        apiImages[ImageClassifier.OFFER_IMAGE_MEDIUM] =
          apiImages[ImageClassifier.OFFER_IMAGE_LARGE];
      }

      //coming soon  Offer details
      let comingSoonOfferImages: Image = {};
      if (comingSoonDetails) {
        let images = comingSoonDetails?.images;
        if (images && images.length) {
          images.forEach((image: any) => {
            let apiImage: any = {
              url: image.imageUrl,
              altText: image.altText || "",
              classifier: image.classifier
            };
            comingSoonOfferImages[image.classifier] = apiImage;
          });
          if (!comingSoonOfferImages[ImageClassifier.OFFER_IMAGE_SMALL]) {
            comingSoonOfferImages[ImageClassifier.OFFER_IMAGE_SMALL] =
              comingSoonOfferImages[ImageClassifier.OFFER_IMAGE_MEDIUM];
          }
          if (!comingSoonOfferImages[ImageClassifier.OFFER_IMAGE_MEDIUM]) {
            comingSoonOfferImages[ImageClassifier.OFFER_IMAGE_MEDIUM] =
              comingSoonOfferImages[ImageClassifier.OFFER_IMAGE_SMALL];
          }
        }
      }

      let comingSoonOffer: ComingSoonDetailsModel = {
        title: comingSoonDetails?.title || "",
        images: comingSoonOfferImages || {},
        shortTitle: comingSoonDetails?.shortTitle || ""
      };

      // check for ExpiryModelType
      const expiryModelType = (
        offerPrizeDetails,
        claimedOffers,
        apiOffer,
        claimedIds
      ) => {
        let voucherExpiryCode;
        let latestClaim;
        if (apiOffer?.offerType === "LUCKY_DIP") {
          latestClaim = getLatestClaimDetailsByClaimId(
            claimedIds,
            claimedOffers
          );
          Object.keys(offerPrizeDetails).forEach(detail => {
            if (
              offerPrizeDetails[detail]?.prizeName === latestClaim?.prizeName
            ) {
              voucherExpiryCode =
                offerPrizeDetails[detail]?.voucherCodeExpiryModelType;
            }
          });
          if (!voucherExpiryCode) {
            voucherExpiryCode = apiOffer.voucherCodeExpiryModelType;
          }
        } else {
          voucherExpiryCode = apiOffer.voucherCodeExpiryModelType
            ? (<any>RedemptionExpiryModel)[apiOffer.voucherCodeExpiryModelType]
            : RedemptionExpiryModel.None;
        }
        return voucherExpiryCode;
      };
      let offer: Offer = {
        redemptionDetails: offerRedemptions,
        // redemptionExpiryModel: apiOffer.voucherCodeExpiryModelType
        //   ? (<any>RedemptionExpiryModel)[apiOffer.voucherCodeExpiryModelType]
        //   : RedemptionExpiryModel.None,
        redemptionExpiryModel: expiryModelType(
          offerPrizeDetails,
          claimedOffers,
          apiOffer,
          claimedIds
        ),
        shareable: !!apiOffer.shareable,
        claimLimitDetails: claimsLimit,
        merchantId: merchant.id,
        claimConditionsFailed: claimConditionsFailed,
        additionalDetails: offerAdditionalDetails,
        offerRating: apiOffer.offerRating,
        callToActionDetails: callToActionDetails,
        images: apiImages,
        instantWinResult: instantWinResult,
        gamificationEmailPopup: gamificationEmailPopup,
        carouselCardTextColor: apiOffer.carouselCardTextColor,
        description: apiOffer.description,
        endDateTime: apiOffer.endDateTime,
        offerId: apiOffer.id,
        keyTerms: apiOffer.keyTerms,
        entryMechanics: apiOffer.entryMechanics,
        offerType: (<any>OfferType)[apiOffer.offerType],
        preClaimAdvice: apiOffer.preClaimAdvice,
        startDateTime: startDateTime,
        teaser: apiOffer.teaser,
        termsAndConditions: apiOffer.termsAndConditions,
        termsAndConditionsUrl: apiOffer.termsAndConditionsUrl,
        title: apiOffer.title
          ? toNonHypenCharachter(apiOffer.title)
          : apiOffer.title,
        titleShort: apiOffer.titleShort
          ? toNonHypenCharachter(apiOffer.titleShort)
          : apiOffer.titleShort,
        claimedIds: claimedIds,
        showPreClaimPopup: showPreClaimPopup,
        addType:
          <any>retireveBannerDetails(offerAdditionalDetails, "type") || "",
        showFullAddImage:
          <any>retireveBannerDetails(offerAdditionalDetails, "showFullImage") ||
          false,
        comingSoonDetails: comingSoonOffer,
        giveToLiteUser: apiOffer.giveToLiteUser,
        offerPrizeDetails: offerPrizeDetails,
        isStoreGiftingOffer: isStoreGiftingOffer,
        isConvergedOffer: apiOffer.isConvergedOffer,
        captureEmail: apiOffer.captureEmail,
        isQRActive: apiOffer.isQRActive,
        isReferralReward: isReferralReward
      };
      apiOffers[apiOffer.id] = offer;
    });
    new Merchants({
      id: "",
      ...apiMerchant
    }).$save();
    new Offers({
      id: "",
      ...apiOffers
    }).$save();
    new OfferIdsSortedByWeight({
      id: "",
      offerIds: offerIdsSortedByWeight
    }).$save();
  }

  //FAQdetails
  const allowedCategories = [
    "Rewards and Codes",
    "Vodafone Together",
    "Eat Local/Local Blend",
    "Subscriptions",
    "PAYG",
    "General",
    "Personal details"
  ];
  if (
    fetchAllApiResponse.appConfig.appSectionList &&
    fetchAllApiResponse.appConfig.appSectionList.length
  ) {
    const { appSectionList } = fetchAllApiResponse.appConfig;
  }
  const sectionList = fetchAllApiResponse?.appConfig?.appSectionList;
  if (sectionList) {
    const faqs = sectionList.filter((sec: any) =>
      allowedCategories.includes(sec.name)
    );
    new Faq({
      id: "",
      description: "11",
      configurationDataList: faqs,
      name: ""
    }).$save();
  }

  // Retriving VisitToWin offertype id, so that we can redirect
  // to offerdetail page from anywhere,
  // adding visitToWin id in AppilcationFlag

  let visitToWinId = 0;
  for (let id in apiOffers) {
    if (apiOffers[id].offerType === OfferType.VISIT_TO_WIN) {
      visitToWinId = +apiOffers[id].offerId;
    }
  }

  //Announcement module data

  if (!isObjectEmpty(fetchAllApiResponse?.announcement)) {
    let announcementData = fetchAllApiResponse?.announcement;

    let announcementInfo: IAnnouncement = {
      title: announcementData.title,
      announcementId: announcementData.id,
      description: announcementData.description,
      ctaRedirect: announcementData.ctaRedirect,
      offerId: announcementData.entityId,
      ctaColor: announcementData.ctaColor,
      ctaText: announcementData.ctaText,
      backgroundImage: announcementData.backgroundImage,
      iconImage: announcementData.iconImage,
      bannerImage: announcementData.bannerImage
    };

    new Announcement({
      id: "",
      ...announcementInfo
    }).$save();
  }

  //ApplicationFlag - where we can store common flag in ApplicationFlag Model
  new ApplicationFlag({
    id: "",
    isShowFooter: true,
    isMyCodesActive: false,
    visitToWinId,
    isShowAnnouncementPopup: true,
    showOfferDetailsDeepLink: offerIdStringValue
  }).$save();
};
